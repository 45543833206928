<template>
  <Fieldset>
    <template #legend>
      <i class="pi pi-discord" style="fontsize: 2rem"></i>
      เฟส 2 - แบบจำลองการประเมินความสามารถในการทำงาน (ตัวแปรสุขภาพ)
    </template>
    <form class="card" @submit.prevent="processML" id="validity">
      <div class="p-fluid p-formgrid p-grid">
        <!-- career -->
        <div class="p-field p-col-12 p-md-12">
          <div class="p-formgroup-inline">
            <label><strong>อาชีพที่ต้องการทำในอนาคต</strong></label
            >&nbsp;&nbsp;
            <div
              class="p-field-checkbox"
              v-for="(career, value) in careers"
              :key="value"
            >
              <RadioButton
                :id="value"
                name="career_value"
                :value="value"
                v-model="careerValue"
              />
              <label :for="value">{{ career.text }}</label>
            </div>
          </div>
        </div>

        <!-- career_need_1 -->
        <!-- sum_tmse -->
        <div v-if="careerValue == 'career_need_1'" class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >1) คะแนนสมรรถภาพสมอง (TMSE)
            <strong>(คะแนนมากคือสมรรถภาพสมองดี)</strong></label
          >
          <br /><br />
          <div class="p-inputgroup" style="width: 77%">
            <InputText
              v-model="criteria['sum_tmse']"
              type="number"
              placeholder="คะแนน"
              :min="0"
              :max="99"
              :required="true"
              style="width: 75%"
              :should="30"
            />
            <Button
              icon="pi pi-search"
              class="p-button-primary"
              @click="() => openModalHelper('sum_tmse', true)"
            />
            &nbsp;&nbsp;<label style="float: right">คะแนน</label>
          </div>
          <label>(คะแนนอยู่ในช่วง 0 – 30 คะแนน)</label>
        </div> 

        <div v-if="careerValue == 'career_need_1'" class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >2) มีปัญหาส่วนของกระดูกสันหลัง</label
          >
          <br />
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A1002"
            :key="key"
          >
            <RadioButton
              name="disfunction_spine"
              v-model="criteria['disfunction_spine']"
              :value="key"
              :required="true"
            />
            <label for="city4">{{ value }}</label>
          </div>
        </div>

        <!-- health_sum -->
        <div v-if="careerValue == 'career_need_1'" class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >3) ผลรวมปัญหาสุขภาพและการใช้ร่างกาย
            <strong>( 0 คะแนนคือไม่มีปัญหา)</strong></label
          >
          <div class="p-inputgroup" style="width: 77%">
            <InputText
              v-model="criteria['health_sum']"
              type="number"
              :min="0"
              :max="99"
              placeholder="คะแนน"
              :required="true"
              style="width: 65%"
              :should="16"
            />
            <Button
              icon="pi pi-search"
              class="p-button-primary"
              @click="() => openModalHelper('health_sum', true)"
            />
            &nbsp;&nbsp;<label>คะแนน</label>
          </div>
          <label>(คะแนนอยู่ในช่วง 0 – 16 คะแนน)</label>
        </div>

        <!-- pain_score -->
        <div v-if="careerValue == 'career_need_1'" class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >4) ประเมินอาการปวดบริเวณร่างกาย
            <strong>(คะแนนน้อยคือสุขภาพดี)</strong></label
          >

          <div class="p-inputgroup" style="width: 77%">
            <InputText
              v-model="criteria['pain_score']"
              type="number"
              placeholder="คะแนน"
              :min="0"
              :max="99"
              :required="true"
              style="width: 65%"
              :should="90"
            />
            <Button
              icon="pi pi-search"
              class="p-button-primary"
              @click="() => openModalHelper('pain_score', true)"
            />
            &nbsp;&nbsp;<label>คะแนน</label>
          </div>
          <label>(คะแนนประเมินอาการปวด อยู่ระหว่าง 0 - 90 คะแนน)</label>
        </div>

        <div v-if="careerValue == 'career_need_1'" class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >5) ช่วงอายุ</label
          >
          <br />
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A1005"
            :key="key"
          >
            <RadioButton
              name="age_range"
              v-model="criteria['age_range']"
              :value="key"
              :required="true"
            />
            <label for="city4">{{ value }}</label>
          </div>
        </div>

        <!-- income_total -->
        <div v-if="careerValue == 'career_need_1'" class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed">6) รายได้ของครอบครัวเฉลี่ยต่อเดือน</label>
          <InputNumber
            v-model="criteria['income_total']"
            placeholder="บาท"
            :min="0"
            :required="true"
            style="width: 65%"
            mode="decimal"
            :minFractionDigits="2"
          />
          &nbsp;<label>บาท</label>
          <label>(ช่วงรายได้ 0 – 100,000 บาท)</label>
        </div>

        <!-- expend_avg -->
        <div v-if="careerValue == 'career_need_1'" class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >7) รายจ่ายครอบครัว เฉลี่ยรวมต่อเดือน</label
          >
          <InputNumber
            v-model="criteria['expend_avg']"
            placeholder="บาท"
            :min="0"
            :required="true"
            style="width: 65%"
            mode="decimal"
            :minFractionDigits="2"
          />
          &nbsp;<label>บาท</label>
          <label>(ช่วงรายจ่าย 0 – 50,000 บาท)</label>
        </div>

        <!-- waistline_inch -->
        <div v-if="careerValue == 'career_need_1'" class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed">8) รอบเอว (นิ้ว)</label>
          <div class="p-inputgroup" style="width: 72%">
            <InputText
              v-model="criteria['waistline_inch']"
              type="number"
              placeholder="นิ้ว"
              :min="0"
              :max="999"
              :required="true"
            />
            &nbsp;&nbsp;<label>นิ้ว</label>
          </div>
        </div>

        <!-- bmi -->
        <div v-if="careerValue == 'career_need_1'" class="p-field p-col-12 p-md-2">
          <label class="p-col-fixed">9) ส่วนสูง</label>
          <InputText
            v-model="criteria['height']"
            type="number"
            placeholder="ซ.ม."
            :min="0"
            :max="999"
            :required="true"
          />
        </div>
        <div v-if="careerValue == 'career_need_1'" class="p-field p-col-12 p-md-2">
          <label class="p-col-fixed">น้ำหนัก</label>
          <InputText
            v-model="criteria['weight']"
            type="number"
            placeholder="ก.ก."
            :min="0"
            :max="999"
            :required="true"
          />
        </div>
        <div v-if="careerValue == 'career_need_1'" class="p-field p-col-12 p-md-2">
          <label class="p-col-fixed">ดัชนีมวลกาย (BMI)</label>
          <InputText
            v-model="criteria['bmi']"
            type="number"
            :readonly="true"
            :required="true"
            style="background-color: #dcdcdc"
          />
        </div>

        <!-- use_smartphone_score -->
        <div v-if="careerValue == 'career_need_1'" class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >10) สามารถใช้มือถือสมาร์ทโฟนในการติดต่อสื่อสารออนไลน์ได้หรือไม่</label
          >
          <br />
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A1010"
            :key="key"
          >
            <RadioButton
              name="use_smartphone_score"
              v-model="criteria['use_smartphone_score']"
              :value="key"
              :required="true"
            />
            <label for="city4">{{ value }}</label>
          </div>
        </div>

        <!-- career_need_2 -->
        <!-- disfunction_spine -->

        <div v-if="careerValue == 'career_need_2'" class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >1) มีปัญหาส่วนของกระดูกสันหลัง</label
          >
          <br />
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A2001"
            :key="key"
          >
            <RadioButton
              name="disfunction_spine"
              v-model="criteria['disfunction_spine']"
              :value="key"
              :required="true"
            />
            <label for="city4">{{ value }}</label>
          </div>
        </div>

        <!-- pain_score -->
        <div v-if="careerValue == 'career_need_2'" class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >2) ประเมินอาการปวดบริเวณร่างกาย
            <strong>(คะแนนน้อยคือสุขภาพดี)</strong></label
          >

          <div class="p-inputgroup" style="width: 77%">
            <InputText
              v-model="criteria['pain_score']"
              type="number"
              placeholder="คะแนน"
              :min="0"
              :max="99"
              :required="true"
              style="width: 65%"
              :should="90"
            />
            <Button
              icon="pi pi-search"
              class="p-button-primary"
              @click="() => openModalHelper('pain_score', true)"
            />
            &nbsp;&nbsp;<label>คะแนน</label>
          </div>
          <label>(คะแนนประเมินอาการปวด อยู่ระหว่าง 0 - 90 คะแนน)</label>
        </div>

        <!-- work_week_hr -->
        <div v-if="careerValue == 'career_need_2'"  class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >3) ในช่วง 1 สัปดาห์ที่ผ่านมา ทำงานสัปดาห์ละกี่ชัวโมง</label
          >
          <InputNumber
            v-model="criteria['work_week_hr']"
            placeholder="ชัวโมง"
            :min="0"
            :max="200"
            :required="true"
            style="width: 75%"
          />
          &nbsp;<label>ชัวโมง</label>
        </div>

        <!-- expend_avg -->
        <div v-if="careerValue == 'career_need_2'"  class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >4) รายจ่ายครอบครัว เฉลี่ยรวมต่อเดือน</label
          >
          <InputNumber
            v-model="criteria['expend_avg']"
            placeholder="บาท"
            :min="0"
            :required="true"
            style="width: 65%"
            mode="decimal"
            :minFractionDigits="2"
          />
          &nbsp;<label>บาท</label>
          <label>(ช่วงรายจ่าย 0 – 50,000 บาท)</label>
        </div>

        <!-- pain_hip_flag -->
        <div v-if="careerValue == 'career_need_2'"  class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >5) ปวดสะโพก</label
          >
          <br />
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A2005"
            :key="key"
          >
            <RadioButton
              name="pain_hip_flag"
              v-model="criteria['pain_hip_flag']"
              :value="key"
              :required="true"
            />
            <label for="city4">{{ value }}</label>
          </div>
        </div>

        <!-- waistline_inch -->
        <div v-if="careerValue == 'career_need_2'"  class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed">6) รอบเอว (นิ้ว)</label>
          <div class="p-inputgroup" style="width: 72%">
            <InputText
              v-model="criteria['waistline_inch']"
              type="number"
              placeholder="นิ้ว"
              :min="0"
              :max="999"
              :required="true"
            />
            &nbsp;&nbsp;<label>นิ้ว</label>
          </div>
        </div>

        <!-- disfunction_lowerrim -->
        <div v-if="careerValue == 'career_need_2'"  class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >7) ปัญหาด้านร่างกายส่วนล่าง</label
          >
          <br />
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A2007"
            :key="key"
          >
            <RadioButton
              name="disfunction_lowerrim"
              v-model="criteria['disfunction_lowerrim']"
              :value="key"
              :required="true"
            />
            <label for="city4">{{ value }}</label>
          </div>
        </div>

        <!-- sum_tmse -->
        <div v-if="careerValue == 'career_need_2'"  class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >8) คะแนนสมรรถภาพสมอง (TMSE)
            <strong>(คะแนนมากคือสมรรถภาพสมองดี)</strong></label
          >
          <br /><br />
          <div class="p-inputgroup" style="width: 77%">
            <InputText
              v-model="criteria['sum_tmse']"
              type="number"
              placeholder="คะแนน"
              :min="0"
              :max="99"
              :required="true"
              style="width: 75%"
              :should="30"
            />
            <Button
              icon="pi pi-search"
              class="p-button-primary"
              @click="() => openModalHelper('sum_tmse', true)"
            />
            &nbsp;&nbsp;<label style="float: right">คะแนน</label>
          </div>
          <label>(คะแนนอยู่ในช่วง 0 – 30 คะแนน)</label>
        </div>

        <!-- bmi -->
        <div v-if="careerValue == 'career_need_2'"  class="p-field p-col-12 p-md-2">
          <label class="p-col-fixed">9) ส่วนสูง</label>
          <InputText
            v-model="criteria['height']"
            type="number"
            placeholder="ซ.ม."
            :min="0"
            :max="999"
            :required="true"
          />
        </div>
        <div v-if="careerValue == 'career_need_2'"  class="p-field p-col-12 p-md-2">
          <label class="p-col-fixed">น้ำหนัก</label>
          <InputText
            v-model="criteria['weight']"
            type="number"
            placeholder="ก.ก."
            :min="0"
            :max="999"
            :required="true"
          />
        </div>
        <div v-if="careerValue == 'career_need_2'"  class="p-field p-col-12 p-md-2">
          <label class="p-col-fixed">ดัชนีมวลกาย (BMI)</label>
          <InputText
            v-model="criteria['bmi']"
            type="number"
            :readonly="true"
            :required="true"
            style="background-color: #dcdcdc"
          />
        </div>

        <!-- disfunction_upperim -->
        <div v-if="careerValue == 'career_need_2'"  class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >10) ปัญหาด้านร่างกายส่วนบน</label
          >
          <br />
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A2010"
            :key="key"
          >
            <RadioButton
              name="disfunction_upperim"
              v-model="criteria['disfunction_upperim']"
              :value="key"
              :required="true"
            />
            <label for="city4">{{ value }}</label>
          </div>
        </div>

        <!-- career_need_3 -->
        <!-- income_main_total -->
        <div v-if="careerValue == 'career_need_3'"  class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >1) รายได้หลักของครอบครัวเฉลี่ยต่อเดือน</label
          >
          <InputNumber
            v-model="criteria['income_main_total']"
            placeholder="บาท"
            :min="0"
            :required="true"
            style="width: 65%"
            mode="decimal"
            :minFractionDigits="2"
          />
          &nbsp;<label>บาท</label>
          <label>(ช่วงรายได้ 0 – 100,000 บาท)</label>
        </div>

        <!-- expend_medicine -->
        <div v-if="careerValue == 'career_need_3'"  class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >2) รายจ่ายในการซื้อยาเฉลี่ยต่อเดือน</label
          >
          <InputNumber
            v-model="criteria['expend_medicine']"
            placeholder="บาท"
            :min="0"
            :required="true"
            style="width: 65%"
            mode="decimal"
            :minFractionDigits="2"
          />
          &nbsp;<label>บาท</label>
          <label>(ช่วงรายจ่าย 0 – 10,000 บาท)</label>
        </div>

        <!-- expend_avg -->
        <div v-if="careerValue == 'career_need_3'"  class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >3) รายจ่ายครอบครัว เฉลี่ยรวมต่อเดือน</label
          >
          <InputNumber
            v-model="criteria['expend_avg']"
            placeholder="บาท"
            :min="0"
            :required="true"
            style="width: 65%"
            mode="decimal"
            :minFractionDigits="2"
          />
          &nbsp;<label>บาท</label>
          <label>(ช่วงรายจ่าย 0 – 50,000 บาท)</label>
        </div>

        <!-- sum_tmse -->
        <div v-if="careerValue == 'career_need_3'"  class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >4) คะแนนสมรรถภาพสมอง (TMSE)
            <strong>(คะแนนมากคือสมรรถภาพสมองดี)</strong></label
          >
          <br /><br />
          <div class="p-inputgroup" style="width: 77%">
            <InputText
              v-model="criteria['sum_tmse']"
              type="number"
              placeholder="คะแนน"
              :min="0"
              :max="99"
              :required="true"
              style="width: 75%"
              :should="30"
            />
            <Button
              icon="pi pi-search"
              class="p-button-primary"
              @click="() => openModalHelper('sum_tmse', true)"
            />
            &nbsp;&nbsp;<label style="float: right">คะแนน</label>
          </div>
          <label>(คะแนนอยู่ในช่วง 0 – 30 คะแนน)</label>
        </div>

        <!-- need_job_hr -->
        <div v-if="careerValue == 'career_need_3'"  class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >5) มารถทำงานเพิ่มได้อีก</label
          >
          <InputNumber
            v-model="criteria['need_job_hr']"
            placeholder="ชัวโมง"
            :min="0"
            :max="24"
            :required="true"
            style="width: 75%"
          />
          &nbsp;<label>ชั่วโมงต่อวัน</label>
        </div>

        <!-- waistline_inch -->
        <div v-if="careerValue == 'career_need_3'"  class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed">6) รอบเอว (นิ้ว)</label>
          <div class="p-inputgroup" style="width: 72%">
            <InputText
              v-model="criteria['waistline_inch']"
              type="number"
              placeholder="นิ้ว"
              :min="0"
              :max="999"
              :required="true"
            />
            &nbsp;&nbsp;<label>นิ้ว</label>
          </div>
        </div>

        <!-- need_job -->
        <div v-if="careerValue == 'career_need_3'"  class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >7) ต้องการจะทำงานเพิ่มหรือไม่</label
          >
          <br />
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A3007"
            :key="key"
          >
            <RadioButton
              name="need_job"
              v-model="criteria['need_job']"
              :value="key"
              :required="true"
            />
            <label for="city4">{{ value }}</label>
          </div>
        </div>

        <!-- pain_score -->
        <div v-if="careerValue == 'career_need_3'"  class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >8) ประเมินอาการปวดบริเวณร่างกาย
            <strong>(คะแนนน้อยคือสุขภาพดี)</strong></label
          >

          <div class="p-inputgroup" style="width: 77%">
            <InputText
              v-model="criteria['pain_score']"
              type="number"
              placeholder="คะแนน"
              :min="0"
              :max="99"
              :required="true"
              style="width: 65%"
              :should="90"
            />
            <Button
              icon="pi pi-search"
              class="p-button-primary"
              @click="() => openModalHelper('pain_score', true)"
            />
            &nbsp;&nbsp;<label>คะแนน</label>
          </div>
          <label>(คะแนนประเมินอาการปวด อยู่ระหว่าง 0 - 90 คะแนน)</label>
        </div>

        <!-- income_total -->
        <div v-if="careerValue == 'career_need_3'"  class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed">9) รายได้ของครอบครัวเฉลี่ยต่อเดือน</label>
          <InputNumber
            v-model="criteria['income_total']"
            placeholder="บาท"
            :min="0"
            :required="true"
            style="width: 65%"
            mode="decimal"
            :minFractionDigits="2"
          />
          &nbsp;<label>บาท</label>
          <label>(ช่วงรายได้ 0 – 100,000 บาท)</label>
        </div>

        <!-- fall_times -->
        <div v-if="careerValue == 'career_need_3'"  class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >10) ในระยะเวลา 6 เดือนที่ผ่านมา ท่านหกล้มกี่ครั้ง</label
          >
          <InputNumber
            v-model="criteria['fall_times']"
            placeholder="ครั้ง"
            :min="0"
            :required="true"
            style="width: 75%"
          />
          &nbsp;<label>ครั้ง</label>
        </div>

        <!-- career_need_4 -->
        <!-- sum_tmse -->
        <div v-if="careerValue == 'career_need_4'"  class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >1) คะแนนสมรรถภาพสมอง (TMSE)
            <strong>(คะแนนมากคือสมรรถภาพสมองดี)</strong></label
          >
          <br /><br />
          <div class="p-inputgroup" style="width: 77%">
            <InputText
              v-model="criteria['sum_tmse']"
              type="number"
              placeholder="คะแนน"
              :min="0"
              :max="99"
              :required="true"
              style="width: 75%"
              :should="30"
            />
            <Button
              icon="pi pi-search"
              class="p-button-primary"
              @click="() => openModalHelper('sum_tmse', true)"
            />
            &nbsp;&nbsp;<label style="float: right">คะแนน</label>
          </div>
          <label>(คะแนนอยู่ในช่วง 0 – 30 คะแนน)</label>
        </div>

        <!-- income_main_total -->
        <div v-if="careerValue == 'career_need_4'"  class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >2) รายได้หลักของครอบครัวเฉลี่ยต่อเดือน</label
          >
          <InputNumber
            v-model="criteria['income_main_total']"
            placeholder="บาท"
            :min="0"
            :required="true"
            style="width: 65%"
            mode="decimal"
            :minFractionDigits="2"
          />
          &nbsp;<label>บาท</label>
          <label>(ช่วงรายได้ 0 – 100,000 บาท)</label>
        </div>

        <!-- sum_sts -->
        <div v-if="careerValue == 'career_need_4'"  class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >3) คะแนนสมรรถภาพทางกาย (STS)
            <strong>(คะแนนมากคือสมรรถภาพทางกายดี)</strong></label
          >
          <br /><br />
          <div class="p-inputgroup" style="width: 77%">
            <InputText
              v-model="criteria['sum_sts']"
              type="number"
              placeholder="คะแนน"
              :min="0"
              :max="99"
              :required="true"
              style="width: 75%"
              :should="30"
            />
            <Button
              icon="pi pi-search"
              class="p-button-primary"
              @click="() => openModalHelper('sum_sts', true)"
            />
            &nbsp;&nbsp;<label style="float: right">คะแนน</label>
          </div>
          <label>(คะแนนอยู่ในช่วง 0 – 30 คะแนน)</label>
        </div>

        <!-- income_total -->
        <div v-if="careerValue == 'career_need_4'"  class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed">4) รายได้ของครอบครัวเฉลี่ยต่อเดือน</label>
          <InputNumber
            v-model="criteria['income_total']"
            placeholder="บาท"
            :min="0"
            :required="true"
            style="width: 65%"
            mode="decimal"
            :minFractionDigits="2"
          />
          &nbsp;<label>บาท</label>
          <label>(ช่วงรายได้ 0 – 100,000 บาท)</label>
        </div>

        <!-- diagnosis_opd_count_6 -->
        <div v-if="careerValue == 'career_need_4'"  class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >5) จำนวนครั้งในการการรักษาผู้ป่วยนอก ในระยะเวลา 6 เดือน</label
          >
          <InputNumber
            v-model="criteria['diagnosis_opd_count_6']"
            placeholder="ครั้ง"
            :min="0"
            :required="true"
            style="width: 75%"
          />
          &nbsp;<label>ครั้ง</label>
        </div>

        <!-- expend_avg -->
        <div v-if="careerValue == 'career_need_4'"  class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >6) รายจ่ายครอบครัว เฉลี่ยรวมต่อเดือน</label
          >
          <InputNumber
            v-model="criteria['expend_avg']"
            placeholder="บาท"
            :min="0"
            :required="true"
            style="width: 65%"
            mode="decimal"
            :minFractionDigits="2"
          />
          &nbsp;<label>บาท</label>
          <label>(ช่วงรายจ่าย 0 – 50,000 บาท)</label>
        </div>

        <!-- gender -->
       <div v-if="careerValue == 'career_need_4'"  class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >7) เพศ</label
          >
          <br />
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A4007"
            :key="key"
          >
            <RadioButton
              name="gender"
              v-model="criteria['gender']"
              :value="key"
              :required="true"
            />
            <label for="city4">{{ value }}</label>
          </div>
        </div>

        <!-- bmi -->
        <div v-if="careerValue == 'career_need_4'"  class="p-field p-col-12 p-md-2">
          <label class="p-col-fixed">8) ส่วนสูง</label>
          <InputText
            v-model="criteria['height']"
            type="number"
            placeholder="ซ.ม."
            :min="0"
            :max="999"
            :required="true"
          />
        </div>
        <div v-if="careerValue == 'career_need_4'"  class="p-field p-col-12 p-md-2">
          <label class="p-col-fixed">น้ำหนัก</label>
          <InputText
            v-model="criteria['weight']"
            type="number"
            placeholder="ก.ก."
            :min="0"
            :max="999"
            :required="true"
          />
        </div>
        <div v-if="careerValue == 'career_need_4'"  class="p-field p-col-12 p-md-2">
          <label class="p-col-fixed">ดัชนีมวลกาย (BMI)</label>
          <InputText
            v-model="criteria['bmi']"
            type="number"
            :readonly="true"
            :required="true"
            style="background-color: #dcdcdc"
          />
        </div>

        <!-- health_sum -->
        <div v-if="careerValue == 'career_need_4'" class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >9) ผลรวมปัญหาสุขภาพและการใช้ร่างกาย
            <strong>( 0 คะแนนคือไม่มีปัญหา)</strong></label
          >
          <div class="p-inputgroup" style="width: 77%">
            <InputText
              v-model="criteria['health_sum']"
              type="number"
              :min="0"
              :max="99"
              placeholder="คะแนน"
              :required="true"
              style="width: 65%"
              :should="16"
            />
            <Button
              icon="pi pi-search"
              class="p-button-primary"
              @click="() => openModalHelper('health_sum', true)"
            />
            &nbsp;&nbsp;<label>คะแนน</label>
          </div>
          <label>(คะแนนอยู่ในช่วง 0 – 16 คะแนน)</label>
        </div>

        <!-- work_week_hr -->
        <div v-if="careerValue == 'career_need_4'"  class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >10) ในช่วง 1 สัปดาห์ที่ผ่านมา ทำงานสัปดาห์ละกี่ชัวโมง</label
          >
          <InputNumber
            v-model="criteria['work_week_hr']"
            placeholder="ชัวโมง"
            :min="0"
            :max="200"
            :required="true"
            style="width: 75%"
          />
          &nbsp;<label>ชัวโมง</label>
        </div>

        <!-- career_need_5 -->
        <!-- gender -->
       <div v-if="careerValue == 'career_need_5'"  class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >1) เพศ</label
          >
          <br />
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A5001"
            :key="key"
          >
            <RadioButton
              name="gender"
              v-model="criteria['gender']"
              :value="key"
              :required="true"
            />
            <label for="city4">{{ value }}</label>
          </div>
        </div>

        <!-- disfunction_spine -->
        <div v-if="careerValue == 'career_need_5'"  class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >2) มีปัญหาส่วนของกระดูกสันหลัง</label
          >
          <br />
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A5002"
            :key="key"
          >
            <RadioButton
              name="disfunction_spine"
              v-model="criteria['disfunction_spine']"
              :value="key"
              :required="true"
            />
            <label for="city4">{{ value }}</label>
          </div>
        </div>

        <!-- income_main_total -->
        <div v-if="careerValue == 'career_need_5'"  class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >3) รายได้หลักของครอบครัวเฉลี่ยต่อเดือน</label
          >
          <InputNumber
            v-model="criteria['income_main_total']"
            placeholder="บาท"
            :min="0"
            :required="true"
            style="width: 65%"
            mode="decimal"
            :minFractionDigits="2"
          />
          &nbsp;<label>บาท</label>
          <label>(ช่วงรายได้ 0 – 100,000 บาท)</label>
        </div>

        <!-- sum_tmse -->
        <div v-if="careerValue == 'career_need_5'"  class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >4) คะแนนสมรรถภาพสมอง (TMSE)
            <strong>(คะแนนมากคือสมรรถภาพสมองดี)</strong></label
          >
          <br /><br />
          <div class="p-inputgroup" style="width: 77%">
            <InputText
              v-model="criteria['sum_tmse']"
              type="number"
              placeholder="คะแนน"
              :min="0"
              :max="99"
              :required="true"
              style="width: 75%"
              :should="30"
            />
            <Button
              icon="pi pi-search"
              class="p-button-primary"
              @click="() => openModalHelper('sum_tmse', true)"
            />
            &nbsp;&nbsp;<label style="float: right">คะแนน</label>
          </div>
          <label>(คะแนนอยู่ในช่วง 0 – 30 คะแนน)</label>
        </div>

        <!-- waistline_inch -->
        <div v-if="careerValue == 'career_need_5'"  class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed">5) รอบเอว (นิ้ว)</label>
          <div class="p-inputgroup" style="width: 72%">
            <InputText
              v-model="criteria['waistline_inch']"
              type="number"
              placeholder="นิ้ว"
              :min="0"
              :max="999"
              :required="true"
            />
            &nbsp;&nbsp;<label>นิ้ว</label>
          </div>
        </div>

        <!-- diagnosis_opd_count_6 -->
        <div v-if="careerValue == 'career_need_5'"  class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >6) จำนวนครั้งในการการรักษาผู้ป่วยนอก ในระยะเวลา 6 เดือน</label
          >
          <InputNumber
            v-model="criteria['diagnosis_opd_count_6']"
            placeholder="ครั้ง"
            :min="0"
            :required="true"
            style="width: 75%"
          />
          &nbsp;<label>ครั้ง</label>
        </div>

        <!-- expend_avg -->
        <div v-if="careerValue == 'career_need_5'"  class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >7) รายจ่ายครอบครัว เฉลี่ยรวมต่อเดือน</label
          >
          <InputNumber
            v-model="criteria['expend_avg']"
            placeholder="บาท"
            :min="0"
            :required="true"
            style="width: 65%"
            mode="decimal"
            :minFractionDigits="2"
          />
          &nbsp;<label>บาท</label>
          <label>(ช่วงรายจ่าย 0 – 50,000 บาท)</label>
        </div>

        <!-- work_week_hr -->
        <div v-if="careerValue == 'career_need_5'"  class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >8) ในช่วง 1 สัปดาห์ที่ผ่านมา ทำงานสัปดาห์ละกี่ชัวโมง</label
          >
          <InputNumber
            v-model="criteria['work_week_hr']"
            placeholder="ชัวโมง"
            :min="0"
            :max="200"
            :required="true"
            style="width: 75%"
          />
          &nbsp;<label>ชัวโมง</label>
        </div>

        <!-- income_total -->
        <div v-if="careerValue == 'career_need_5'"  class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed">9) รายได้ของครอบครัวเฉลี่ยต่อเดือน</label>
          <InputNumber
            v-model="criteria['income_total']"
            placeholder="บาท"
            :min="0"
            :required="true"
            style="width: 65%"
            mode="decimal"
            :minFractionDigits="2"
          />
          &nbsp;<label>บาท</label>
          <label>(ช่วงรายได้ 0 – 100,000 บาท)</label>
        </div>

        <!-- bmi -->
        <div v-if="careerValue == 'career_need_5'"  class="p-field p-col-12 p-md-2">
          <label class="p-col-fixed">10) ส่วนสูง</label>
          <InputText
            v-model="criteria['height']"
            type="number"
            placeholder="ซ.ม."
            :min="0"
            :max="999"
            :required="true"
          />
        </div>
        <div v-if="careerValue == 'career_need_5'"  class="p-field p-col-12 p-md-2">
          <label class="p-col-fixed">น้ำหนัก</label>
          <InputText
            v-model="criteria['weight']"
            type="number"
            placeholder="ก.ก."
            :min="0"
            :max="999"
            :required="true"
          />
        </div>
        <div v-if="careerValue == 'career_need_5'"  class="p-field p-col-12 p-md-2">
          <label class="p-col-fixed">ดัชนีมวลกาย (BMI)</label>
          <InputText
            v-model="criteria['bmi']"
            type="number"
            :readonly="true"
            :required="true"
            style="background-color: #dcdcdc"
          />
        </div>
      </div>

      <div class="p-d-flex p-jc-center">
        <Button
          type="submit"
          label="วิเคราะห์ผล"
          :icon="['pi pi-spinner', isLoading ? 'pi-spin' : '']"
          iconPos="left"
        />
      </div>
      <Divider align="left" type="solid">
        <b>อธิบายเพิ่มเติม</b>
      </Divider>
      <ul>
        <li>
          เปอร์เซ็นต์ความแม่นยำในการทำนายความสามารถในการทำงาน อยู่ที่
          {{ mapCareerPercentLabel }}
          <br />
          <strong v-if="careerValue != 'career_all'"
            >(
            <span style="color: red"
              >กรณี
              {{ mapCareerLabel }}
              ข้อมูลในการวิเคราะห์เพื่อสร้างแบบจำลองมีจำนวนน้อย
              <br />มีผลต่อความคลาดเคลื่อนในการประเมินความสามารถในการทำงานผู้สุงอายุ</span
            >
            )</strong
          >
        </li>
        <li>ผลวิเคราะห์ความสามารถในการทำงาน มี 2 ระดับ</li>
        <ol >
          <li style="color: red"><strong>ทำงานได้ต่ำกว่าเกณฑ์</strong></li>
          <li style="color: green"><strong>ทำงานได้สูงกว่าเกณฑ์</strong><br /></li>
        </ol>
      </ul>
    </form>
  </Fieldset>
  <Dialog
    :header="'ผลการวิเคราะห์ความสามารถในการทำงาน' + mapCareerLabel"
    v-model:visible="isModal"
    :style="{ width: '50vw' }"
  >
    <p class="p-d-flex p-jc-center" v-html="mapPredictScoreHtml"></p>
    <template #footer>
      <Button
        label="ปิด"
        icon="pi pi-times"
        @click="() => (isModal = false)"
        class="p-button-text"
      />
    </template>
  </Dialog>
  <ModalSumSts />
  <ModalSumTmse />
</template>

<script>
import axios from "axios";
import { initCustomValidity } from "@/HTMLElement";
import { mixins } from "../Mixins";

import ModalSumSts from "../prediction/ModalSumSts.vue";
import ModalSumTmse from "../prediction/ModalSumTmse.vue";

export default {
  mixins: [mixins],
  layout: "Private",
  data() {
    return {
      A1002: {
        1: "มีปัญหา",
        0: "ไม่มีปัญหา",
      },
      A1005: {
        1: "60 – 65 ปี ",
        2: "66 -70 ปี ",
        3: "> 70 ปี ",
      },
      A1010: {
        1: "ได้",
        0: "ไม่ได้",
      },
      A2001: {
        1: "มีปัญหา",
        0: "ไม่มีปัญหา",
      },
      A2005: {
        1: "ปวด",
        0: "ไม่ปวด",
      },
      A2007: {
        1: "มีปัญหา",
        0: "ไม่มีปัญหา",
      },
      A2010: {
        1: "มีปัญหา",
        0: "ไม่มีปัญหา",
      },
      A3007: {
        2: "ต้องการ",
        1: "ไม่ต้องการ",
      },
      A4007: {
        1: "ชาย",
        0: "หญิง",
      },
      A5001: {
        1: "ชาย",
        0: "หญิง",
      },
      A5002: {
        1: "มีปัญหา",
        0: "ไม่มีปัญหา",
      },
      careerValue: "career_need_1",
      criteria: this.getCareerCriteria("career_need_1"),
      careers: {
        career_need_1: { text: "ค้าขายอาหาร", percent: "76" },
        career_need_2: { text: "ทำไร่/ทำสวน", percent: "77" },
        career_need_3: { text: "กรีดยาง", percent: "90" },
        career_need_4: { text: "ทำสวนผลไม้", percent: "100" },
        career_need_5: { text: "ทำนา", percent: "90" },
        //career_need_6: 'ไม่เลือก 7 อาชีพ'
      },
    };
  },
  watch: {
    careerValue: {
      handler(value) {
        this.criteria = this.getCareerCriteria(value);
        this.$store.commit('entryTrain/updateTablePain', value);
      },
      immediate: true,
    },
    'criteria.weight'(newVal, oldVal) {
      console.log('weight newVal ::==', newVal)
      console.log('weight oldVal ::==', oldVal)
      this.calBMI()
    },
    'criteria.height'(newVal, oldVal) {
      console.log('height newVal ::==', newVal)
      console.log('height oldVal ::==', oldVal)
      this.calBMI()
    },
    '$store.state.prediction.labelsValue': {
      handler(newVal, oldVal) {
        console.log('newVal ::==', JSON.stringify(newVal))
        console.log('oldVal ::==', oldVal)
        this.criteria = { ...this.criteria, ...newVal }
      },
      deep: true
    }
  },
  computed: {
    mapPredictScoreHtml() {
      const { text = "-", color } = this.grade[this.score] || {
        color: "black",
      };
      return `<h1 style="color:${color};font-size:50px;font-weight: bold;"> ${text}</h1>`;
    },
    mapCareerLabel() {
      const { text } = this.careers[this.careerValue];
      return ` อาชีพ: ${text}`;
    },
    mapCareerPercentLabel() {
      const { percent } = this.careers[this.careerValue];
      console.log("percent ::==", percent);
      return `${percent}%`;
    },
    dataSet() {
      return this.$store.state.entryTrain.entity;
    },
  },
  mounted() {
    this.$nextTick(() => {
      console.log("initCustomValidity validate thai message !");
      initCustomValidity("validity");
    });
  },
  methods: {
    openModalHelper(modalName, isOpen) {
      //console.log('modalName ::==', modalName)
      this.$store.commit("prediction/setModal", { modalName, isOpen });
    },
    async calBMI() {
      // weight/((height/100)*(height/100));
      const { weight = 0, height = 0 } = this.criteria
      // Math.round(num * 100) / 100
      this.criteria.bmi = (
        Math.round((weight / ((height / 100) * (height / 100))) * 100) / 100
      ).toFixed(0)
    },
    async processML() {
      const { VUE_APP_API_ENDPOINT } = process.env;
      let payload = {
        labels: this.criteria,
        loop: 10,
        area_id: 999,
        career_value: this.careerValue,
        route: "predict2",
      };
      this.message = null;
      this.isLoading = true;

      //payload['diagnosis_drug_count'] = null

      //alert('payload ::==' + JSON.stringify(payload))
      try {
        const { data: result } = await axios({
          url: `${VUE_APP_API_ENDPOINT}/predict/train3`, // labels=weight,bmi,waistline&area_id=999&algo=2
          data: payload,
          method: "POST",
        });
        const { status, data } = result;
        if (status.code == "01") {
          alert(
            "เกิดข้อผิดพลาดในระหว่างการวิเคราะห์ผล กรุณาติดต่อเจ้าหน้าที่ดูแลระบบ"
          );
        } else {
          var dataJson = JSON.parse(data);
          var score = dataJson.datas.score;
          this.message = JSON.stringify({
            ...data,
            grade: this.grade[score] || "ดีมาก",
          });
          this.score = score;
          this.isModal = true;
        }
      } catch (error) {
        console.log("error ::==", error);
        alert(
          "เกิดข้อผิดพลาดในระหว่างการวิเคราะห์ผล กรุณาติดต่อเจ้าหน้าที่ดูแลระบบ"
        );
      }
      this.isLoading = false;
    },
    getCareerCriteria(careerValue) {
      if (careerValue == "career_need_1") {
        return {
          sum_tmse: null,
          disfunction_spine: null,
          health_sum: null,
          pain_score: null,
          age_range: null,
          income_total: null,
          expend_avg: null,
          waistline_inch: null,
          bmi: null,
          use_smartphone_score: null,
        };
      } else if (careerValue == "career_need_2") {
        return {
          disfunction_spine: null,
          pain_score: null,
          work_week_hr: null,
          expend_avg: null,
          pain_hip_flag: null,
          waistline_inch: null,
          disfunction_lowerrim: null,
          sum_tmse: null,
          bmi: null,
          disfunction_upperim: null,
        };
      } else if (careerValue == "career_need_3") {
        return {
          income_main_total: null,
          expend_medicine: null,
          expend_avg: null,
          sum_tmse: null,
          need_job_hr: null,
          waistline_inch: null,
          need_job: null,
          pain_score: null,
          income_total: null,
          fall_times: null,
        };
      } else if (careerValue == "career_need_4") {
        return {
          sum_tmse: null,
          income_main_total: null,
          sum_sts: null,
          income_total: null,
          diagnosis_opd_count_6: null,
          expend_avg: null,
          gender: null,
          bmi: null,
          health_sum: null,
          work_week_hr: null,
        };
      } else if (careerValue == "career_need_5") {
        return {
          gender: null,
          disfunction_spine: null,
          income_main_total: null,
          sum_tmse: null,
          waistline_inch: null,
          diagnosis_opd_count_6: null,
          expend_avg: null,
          work_week_hr: null,
          income_total: null,
          bmi: null,
        };
      }
    },
  },
  components: {
    ModalSumSts,
    ModalSumTmse,
  },
};
</script>

<style lang="scss" scoped>
</style>